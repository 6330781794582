.news {
  width: 100%;
  .type-interview {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: row;
    position: relative;
    .type-interview-background {
      width: 25%;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }
  .news-holder {
    width: 100%;
    padding: 0 50px;
    .clear {
      clear: both;
    }
    div {
      width: calc((100% / 3) - 20px);
      float: left;
      margin: 0 10px;
      a.news-block {
        color: #000000;
        .new {
          width: 100%;
          padding: 10px;
          margin: 20px 0;
          background-color: $grey;
          h1 {
            margin-bottom: 10px;
            line-height: 1.2;
            font-size: 16px;
          }
          .date {
            margin-bottom: 10px;
            color: $blue;
            line-height: 1.1;
            font-size: 16px;
            font-weight: 700;
          }
          img {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
          }
          .description {
            line-height: 1.1;
            font-size: 16px;
          }
        }
      }
    }
  }
}