.auth-form {
  width: 100%;
  max-width: 500px;
  margin: 32px auto;
  position: relative;
  background-color: #F5F5F5;
  padding: 32px;
  border-radius: 3px;

  h4 {
    color: #3382a3;
    font: 500 18px/24px "Roboto", Arial, sans-serif;
    text-align: center;
    margin-bottom: 16px;
  }
  form {
    position: relative;
    padding: 32px 64px 12px 64px;
  }

  input, textarea, select {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    background-color: white;
    color: #212121;
    border-radius: 3px;
    border: 1px solid transparent;
    padding: 16px;
    margin-bottom: 12px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: all ease .15s;
    outline: none;
    &:active, &:focus {
      border-bottom-color: #3c94ba;
      border-radius: 3px 3px 0 0;
    }
  }
  input {
    padding-right: 64px;
  }
  select {
    cursor: pointer;
  }
  option[selected] {
    color: #CCCCCC;;
  }
  .auth-button {
    width: 150px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 4px 12px;
    font-weight: normal;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #199bd7;
    border: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    margin: 0 auto;

    &:hover {
      background-color: lighten(#199bd7,5%);
    }
    &:active {
      background-color: darken(#199bd7,5%);
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: .65;
      color: #F5F5F5;
    }
  }
  .has-feedback {
    position: relative;
  }
  .has-succsess {

    input {
      border-color: #86ca77;
      &:active, &:focus {
        border-radius: 3px;
      }
    }

    .success-icon {
      display: block !important;
    }
  }

  .has-error {

    input {
      border-color: #ea5038;
      &:active, &:focus {
        border-radius: 3px;
      }
    }

    .error-icon {
      display: block;
    }
  }

  .success-icon {
    display: none;
    position: absolute;
    right: 16px; top: 10px;
    width: 30px;
    height: 30px;
    background-image: url("../img/tick-success.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
  }

  .error-icon {
    display: none;
    position: absolute;
    right: 16px; top: 10px;
    width: 30px;
    height: 30px;
    background-image: url("../img/error-icon.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
  }

  .chip-attention {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    padding-right: 64px;
    margin-bottom: 12px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #ea5038;
    border-radius: 0 0 3px  3px ;

    .attention-text {
      font: 500 1rem/1.4rem;
      color: #fff;
      margin: 0;
    }
  }

  .paragraph {
    margin-bottom: 16px;
    line-height: 1.3;
  }
}

.success-box {
  display: none;
  width: 100%;
  padding: 24px 16px;
  text-align: center;
  background-color: #F5F5F5;

  h2 {
    text-align: center;
    font-size: 32px;
  }
  p {
    font: 400 1rem/1.25rem;
    text-align: center;
    color: #212121;
  }
  .relink {
    display: inline-block;
    vertical-align: top;
    margin: 2px;
    background-color: #3c94ba;
    padding: 12px 16px;
    color: #ffffff;
    font-size: 400 1rem/1.25rem ;
    border-radius: 3px ;
    transition: all ease .15s;
    &:hover {
      background-color: lighten(#3c94ba,5%);
    }
    &:active {
      background-color: darken(#3c94ba,5%);
    }
  }


}
.form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .auth-button {
    margin: 0;
  }
}
.forgot-pass {
  color: #199bd7;
  font-size: 14px;
}
.not-error {
  border: 1px solid #86ca77 !important;
}
.error {
  border: 1px solid #ea5038 !important;
}
.contact-text {
  padding: 16px;
}
.align-center {
  text-align: center;
}