.content {
  width: 100%;
  min-height: calc(100% - 135px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .middle {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    background-color: #ffffff;
    .bread-crumbs {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 12px 12px 50px;
      background-color: $grey;
      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: $header;
      }
      form {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        input {
          height: 29px;
          padding: 5px;
        }
        button {
          width: 29px;
          height: 29px;
          border: none;
          margin-left: 10px;
          background-color: transparent;
          background-image: url("../img/search.svg");
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: auto 80%;
          cursor: pointer;
          &:active {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.type-interview-background {
  width: 25%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  &:nth-child(1) {
    background-image: url("../img/type-1.jpg");
  }
  &:nth-child(2) {
    background-image: url("../img/type-2.jpg");
  }
  &:nth-child(3) {
    background-image: url("../img/type-3.jpg");
  }
  &:nth-child(4) {
    background-image: url("../img/type-4.jpg");
  }
}