.header {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: $header;
  border-bottom: 15px solid $borders;
  .professions-guide {
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0 10px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.2;
    text-transform: uppercase;
    transition: all .5s ease;
    &:hover {
      cursor: pointer;
      background-color: #ffffff;
      color: $header;
    }
  }
  .navigation {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .item {
      width: 156px;
      height: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: center;
      color: #ffffff;
      border-left: 1px solid #ffffff;
      font-size: 12px;
      text-transform: uppercase;
      transition: all .5s ease;
      &:hover {
        cursor: pointer;
        background-color: #ffffff;
        color: $header;
      }
      &:last-child {
        border-right: 1px solid #ffffff;
      }
    }
  }
  .login {
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0 10px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.2;
    text-transform: uppercase;
    transition: all .5s ease;
    &:hover {
      cursor: pointer;
      background-color: #ffffff;
      color: $header;
    }
  }
}