.contacts {
  display: flex;
  flex-direction: row;
  .sider {
    width: 50%;
    padding-top: 0;
    padding-bottom: 50px;
    &:first-child {
      padding-left: 50px;
      padding-right: 25px;
    }
    &:last-child {
      text-align: center;
      padding-left: 25px;
      padding-right: 50px;
    }
    .logotype {
      margin: 30px auto 50px;
    }
    .main {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    .some-text {
      margin-bottom: 40px;
      font-size: 16px;
    }
    .inform-buro {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 700;
      color: $header;
    }
    .phone, .email {
      margin-bottom: 20px;
      line-height: 2;
      span {
        color: $blue;
        font-weight: 700;
      }
    }
    p {
      font-size: 14px;
    }
    .form {
      color: $header;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
      text-align: right;
    }
    .form-title {
      font-size: 12px;
      margin-bottom: 5px;
      text-align: right;
      span {
        color: $red;
      }
    }
    input, textarea {
      width: 100%;
      margin-bottom: 5px;
      border: 1px solid $blue;
      border-radius: 3px;
      &:focus { outline: none; }
    }
    textarea {
      height: 100px;
      font-family: 'Roboto';
    }
    button {
      width: 150px;
      height: 50px;
      margin: 0 auto;
      background-color: $red;
      color: #ffffff;
      font-weight: 300;
      font-size: 14px;
      text-transform: uppercase;
      border: none;
      border-radius: 3px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}