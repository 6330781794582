.interviews {
  width: 100%;
  &.unactived {
    .switched {
      .actived-interview {
        background-color: $header;
        color: #ffffff;
        p {
          span {
            color: #000000;
          }
        }
        .triangle {
          border-top: none;
          border-left: 20px solid transparent;
          border-bottom: 40px solid $grey;
          border-right: none;
        }
      }
      .unactived-interview {
        background-color: $grey;
        p {
          color: $header;
          span {
            color: #000000;
          }
        }
        .triangle {
          background-color: $grey;
        }
      }
    }
    .interviews-holder {
      .interview {
        .text {
          h1 {
            color: $blue;
          }
        }
        .chart-holder {
          .chart {
            background-color: $blue_h;
          }
        }
        .settings {
          .transition {
            background-color: $blue;
          }
        }
      }
    }
  }
  .type-interview {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 0 0 20px;
    .type-interview-checker {
      width: auto;
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $blue;
      border-radius: 3px;
      a {
        padding: 10px 15px 8px;
        margin: 10px;
        font-size: 14px;
        text-transform: uppercase;
        color: #ffffff;
        border: 1px solid transparent;
        border-radius: 3px;
        line-height: 1;
        transition: all .5s ease;
        &:hover {
          text-shadow: 1px 1px 1px #13365E;
          border: 1px solid $header;
          cursor: pointer;
        }
        &:active {

        }
        &.active {
          background-color: $header;
        }
      }
      a.public {

      }
      a.private {

      }
      a.all {

      }
    }
  }
  .switched {
    width: 100%;
    display: flex;
    flex-direction: row;
    .actived-interview {
      width: 50%;
      padding: 10px 20px 10px 50px;
      background-color: $grey;
      position: relative;
      cursor: pointer;
      .triangle {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: 0;
        border-top: 40px solid $header;
        border-left: 20px solid transparent;
      }
      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          min-width: 50px;
          padding: 4px 10px 2px;
          margin-top: -2px;
          margin-left: 20px;
          background-color: #ffffff;
          border: 1px solid $blue;
          border-radius: 3px;
        }
      }
    }
    .unactived-interview {
      width: 50%;
      padding: 10px 20px 10px 50px;
      background-color: $header;
      position: relative;
      cursor: pointer;
      .triangle {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: 0;
        border-top: 40px solid #ffffff;
        border-left: 20px solid transparent;
      }
      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          min-width: 50px;
          padding: 4px 10px 2px;
          margin-top: -2px;
          margin-left: 20px;
          background-color: #ffffff;
          border: 1px solid $blue;
          border-radius: 3px;
          color: #000000;
        }
      }
    }
  }
  .interviews-holder {
    background-color: $grey;
    padding: 20px 0 1px;
    .interview {
      margin: 0 20px 20px;
      padding: 30px;
      background-color: #ffffff;
      .text {
        img {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }
        h1 {
          text-transform: uppercase;
          font-size: 26px;
          font-weight: 300;
          color: $red;
          margin-bottom: 20px;
          line-height: 1.2;
        }
        p {
          margin-bottom: 20px;
          line-height: 1.2;
        }
      }
      .chart-holder {
        width: 100%;
        height: 170px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        .period-chart {
          width: 100px;
          height: 120px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: space-around;
          background-color: $blue;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: -70px;
            left: 0;
            border: 50px solid transparent;
            border-top: 20px solid $blue;
          }
          p {
            width: 100%;
            color: #ffffff;
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            span {
              font-size: 12px;
            }
          }
        }
        .chart {
          width: calc(100% - 140px);
          background-color: $chart;
        }
      }
      .settings {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        .sider {
          width: calc(50% - 85px);
          .humans-count {
            width: 100px;
            height: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: center;
            background-color: $blue_m;
            border: 1px solid $header;
            border-radius: 50%;
            .inner-round {
              width: 80px;
              height: 80px;
              display: flex;
              flex-direction: row;
              align-items: center;
              background-color: $blue_h;
              border: 1px solid $header;
              border-radius: 50%;
              p {
                margin-top: 4px;
                font-size: 10px;
                font-weight: 700;
                line-height: 1.2;
                color: $header;
                text-align: center;
              }
            }
          }
          .timer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .remaining-time {
              margin: 0 16px 0 0;
              font-size: 10px;
              font-weight: 700;
              line-height: 1.2;
              color: $header;
              text-align: center;
            }
            .colon {
              .colon-round {
                width: 4px;
                height: 4px;
                margin: 6px;
                background-color: $header;
                border-radius: 50%;
              }
              &-empty {
                width: 16px;
              }
            }
            .timer-count {
              width: 60px;
              height: 60px;
              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;
              justify-content: center;
              position: relative;
              background-color: $blue_m;
              border: 1px solid $header;
              border-radius: 50%;
              .indexer {
                color: $header;
                font-size: 14px;
                position: absolute;
                bottom: -25px;
                left: 50%;
                transform: translate(-50%, 0);
              }
              .inner-round {
                width: 50px;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                justify-content: center;
                background-color: $blue_h;
                border: 1px solid $header;
                border-radius: 50%;
                p {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 1.2;
                  color: $header;
                  text-align: center;
                }
              }
            }
          }
        }
        .transition {
          width: 150px;
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: center;
          padding-top: 4px;
          margin: 0 10px;
          color: #fff;
          font-size: 14px;
          border-radius: 3px;
          text-transform: uppercase;
          background-color: #ef4138;
        }
      }
    }
  }
}