.index {
  width: 100%;
  height: 100%;
  .background {
    width: 100%;
    max-width: 980px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    padding: 40px 0;
    background-image: url("../img/main.jpg");
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    .calculate {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 40px 0;
      .item {
        width: 160px;
        height: 160px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 1px solid $header;
        border-radius: 50%;
        background-color: rgba(25, 155, 215, 0.5);
        .inner-item {
          width: 140px;
          height: 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid $header;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          p {
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            line-height: 1.2;
            span {}
          }
        }
      }
    }
    .to-interview {
      width: 170px;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: center;
      padding-top: 2px;
      margin: 0 auto;
      background-color: $red;
      color: #ffffff;
      font-weight: 300;
      font-size: 14px;
      text-transform: uppercase;
      border: none;
      border-radius: 3px;
      &:hover {
        cursor: pointer;
      }
    }
    .logotype {
      width: 160px;
      height: 130px;
      margin: 0 auto;
      .logo {
        width: 160px;
        height: 130px;
        display: flex;
        flex-direction: column;
        position: relative;
        &:before {
          content: '';
          width: 19px;
          height: 19px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background-image: url(../img/2-head-eagle.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
        }
      }
      .mintrud-logotype {
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        margin-bottom: 4px;
      }
      .mintrud-logotype-sign {
        display: block;
        text-transform: uppercase;
        color: #333;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        margin-bottom: 5px;
      }
      .mintrud-logotype-rf {
        display: block;
        text-transform: uppercase;
        color: #333;
        text-align: center;
        font-size: 10px;
        font-stretch: ultra-condensed
      }
      .logo .leftline, .sub-logo .leftline {
         left: 0;
       }
      .logo .leftline, .logo .rightline, .sub-logo .leftline, .sub-logo .rightline {
        width: calc(50% - 20px);
        height: 2px;
        position: absolute;
        bottom: 8px;
        background-color: #dedede;
      }
      .logo .rightline, .sub-logo .rightline {
        right: 0;
      }
      .logo .leftline, .logo .rightline, .sub-logo .leftline, .sub-logo .rightline {
        width: calc(50% - 20px);
        height: 2px;
        position: absolute;
        bottom: 8px;
        background-color: #dedede;
      }
    }
  }
  .partners {
    width: 100%;
    max-width: 980px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    background-color: #ffffff;
    img {
      width: auto;
      height: 70px;
      margin: 15px;
      &#moinrf {
        height: 50px;
      }
      &#nspk {
        height: 40px;
      }
      &#fgbu {
        height: 50px;
      }
      &img#ws {
        height: 60px;
      }
    }
  }
}