.news-inner {
  padding: 20px 50px;
  h1 {
    margin-bottom: 20px;
    color: $header;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.2;
  }
  .date {
    color: $blue;
    font-size: 12px;
    font-weight: 700;
  }
  .subtitle {
    font-weight: 700;
  }
  p {
    line-height: 1.2;
    margin-bottom: 20px;
  }
}