$header: #13365e;
$borders: #0e5072;
$blue: #199bd7;
$blue_h: #eaf3fc;
$blue_m: #98c4e9;
$grey: #e8e8e8;
$red: #ef4138;
$chart: #f6ddc7;

@import "parts/reset",
"parts/header",
"parts/content",
"pages/index",
"pages/interview",
"pages/news",
"pages/new",
"pages/contacts",
"pages/auth",
"parts/footer";

html, body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: $blue;
}

svg {
  display: none;
}

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.middle {
  overflow: hidden;
}