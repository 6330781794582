.footer {
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-top: 15px solid $borders;
  .social-icon {
    width: 20px;
    height: 20px;
    margin: 2px;
    background-position: 50% 50%;
    background-size: 20px auto;
    background-repeat: no-repeat;
    cursor: pointer;
    &#facebook {
      background-image: url("../img/facebook.svg");
    }
    &#twitter {
      background-image: url("../img/twitter.svg");
    }
    &#instagram {
      background-image: url("../img/instagram.svg");
    }
    &#vk {
      background-image: url("../img/vk.svg");
    }
    &#google {
      background-image: url("../img/google-plus.svg");
    }
  }
}